/*Play Button hide Rule*/


.hide-playbtn::-webkit-media-controls-play-button{
    display: none !important;
}

/*Audio Button hide Rule*/
.hide-audiobtn::-webkit-media-controls-volume-control-container{
    display: none;
}

/*Progress Bar hide Rule*/
.hide-progressbar::-webkit-media-controls-timeline{
    display: none;
}

/*Other Controls (Current Time, Remaining Time, Fullscreen )*/
.hideOtherControls::-webkit-media-controls-overflow-button {
	display: none;
} 
.hideOtherControls::-webkit-media-controls-time-remaining-display {
	display: none;
}
.hideOtherControls::-webkit-media-controls-fullscreen-button {
	display: none;
}

video::-webkit-media-controls-volume-control-container{
    justify-content: flex-end;
}

video::-webkit-media-controls:not(.audio-only) [pseudo="-webkit-media-controls-panel"] [pseudo="-internal-media-controls-overflow-button"] {
    display: none !important;
}

